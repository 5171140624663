<template>
  <div class="report" :style="reportStyle">
    <hostes-report-filter @submit="onFilterSubmit" />
    <hostes-report-table :height="height" @save-csv="onSaveCsv" @save-xls="onSaveXls" />
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import HostesReportFilter from './HostesReport/HostesReportFilter'
import HostesReportTable from './HostesReport/HostesReportTable'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'HostesReport',
  components: { HostesReportTable, HostesReportFilter },
  data () {
    return {
      height: 500
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    ...mapFields({
      reportCsv: 'reports.hostesReport.reportCsv',
      reportXls: 'reports.hostesReport.reportXls'
    }),
    reportStyle () {
      let style = ''
      if (this.deviceInfo?.type === 'mobile' || this.deviceInfo?.type === 'tablet') {
        style += 'overflow: hidden; overflow-y: auto; max-height: calc(100svh - 80px);'
      }
      return style
    }
  },
  async mounted () {
    this.calcHeight()
    await this.fetchReportData()
    this.$nextTick(() => {
      this.calcHeight()
    })
    if (window) {
      window.addEventListener('resize', this.calcHeight)
    }
  },
  beforeDestroy () {
    if (window) {
      window.removeEventListener('resize', this.calcHeight)
    }
  },
  methods: {
    ...mapActions({
      fetchReportData: 'reports/hostesReport/fetchReportData',
      fetchReportCsv: 'reports/hostesReport/fetchReportCsv',
      fetchReportXls: 'reports/hostesReport/fetchReportXls'
    }),
    async onFilterSubmit () {
      await this.fetchReportData()
      this.$nextTick(() => {
        this.calcHeight()
      })
    },
    calcHeight () {
      if (!isServer && window && this.deviceInfo?.type !== 'mobile') {
        const $navbar = document.querySelector('.navbar')
        const $tHeader = document.querySelector('.report-filter')
        const $tHead = document.querySelector('.report-table .table thead')
        const $tPager = document.querySelector('.report-table .card-header')
        const navbarHeight = $navbar?.clientHeight || 0
        const tHeaderHeight = $tHeader?.clientHeight || 0
        const tHeadHeight = $tHead?.clientHeight || 0
        const tPagerHeight = $tPager?.clientHeight || 0
        const windowHeight = window.innerHeight
        this.height = windowHeight - navbarHeight - tHeaderHeight - tHeadHeight - tPagerHeight
      }
    },
    async onSaveCsv () {
      await this.fetchReportCsv()
      const k = moment().format('DDMMYYHHmmss')
      const filename = `hostes-report-${k}.csv`
      const file = new Blob([this.reportCsv], { type: 'text/plain;charset=utf-8' })
      this.$nextTick(() => {
        this.reportCsv = null
      })
      if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename)
      } else {
        // Others
        const a = document.createElement('a')
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        this.$nextTick(() => {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        })
      }
    },
    async onSaveXls () {
      await this.fetchReportXls()
      const k = moment().format('DDMMYYHHmmss')
      const filename = `hostes-report-${k}.xlsx`
      const file = new Blob([this.reportXls], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      this.$nextTick(() => {
        this.reportXls = null
      })
      if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename)
      } else {
        // Others
        const a = document.createElement('a')
        const url = URL.createObjectURL(file)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        this.$nextTick(() => {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        })
      }
    }
  }
}
</script>

<style scoped>
.report {
  overflow-y: auto;
}
</style>
