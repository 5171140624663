var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{ref:"table",staticClass:"p-0 report-table"},[_c('b-card-header',{staticClass:"d-flex align-items-center py-3"},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('div',{staticClass:"h5"},[_vm._v("\n        "+_vm._s(_vm.$t('rPayment_total'))+": "),_c('span',{staticClass:"text-secondary"},[_vm._v("\n          "+_vm._s(_vm.reportTotals)+"\n          "),_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['fas', 'euro-sign']}})],1)])]),_vm._v(" "),_c('div',{staticClass:"ml-auto"}),_vm._v(" "),_c('b-dropdown',{staticClass:"m-md-2",attrs:{"text":_vm.$t('eLt_export_title'),"size":"sm"}},[_c('b-dropdown-item',{on:{"click":_vm.onSaveCsv}},[_vm._v("\n        "+_vm._s(_vm.$t('eLt_export_csv'))+"\n      ")]),_vm._v(" "),_c('b-dropdown-item',{on:{"click":_vm.onSaveXls}},[_vm._v("\n        "+_vm._s(_vm.$t('eLt_export_xls'))+"\n      ")])],1)],1),_vm._v(" "),_c('b-table',{staticClass:"text-nowrap",attrs:{"fields":_vm.fields,"items":_vm.tableItems,"sticky-header":(_vm.height + "px"),"striped":"","hover":"","small":"","responsive":"","foot-clone":"","thead-class":"bg-primary text-white text-center small"},scopedSlots:_vm._u([{key:"cell(user)",fn:function(ref){
var user = ref.item.user;
return [_c('user-profile-item',{attrs:{"user":user}})]}},{key:"cell(status)",fn:function(ref){
var status = ref.item.status;
return [_c('b-badge',{attrs:{"variant":_vm.getStatusVariant(status)}},[_vm._v("\n        "+_vm._s(_vm.getStatusText(status))+"\n      ")])]}},{key:"cell(market)",fn:function(ref){
var user = ref.item.user;
return [_c('market-flag',{attrs:{"market":user.market}})]}},{key:"cell(club_member)",fn:function(ref){
var item = ref.item;
return [(item.club_member)?_c('b-button',{attrs:{"pill":"","disabled":"","variant":"warning","size":"sm"}},[_c('font-awesome-icon',{staticClass:"text-white",attrs:{"icon":['fas', 'star']}})],1):_vm._e()]}}],null,true)}),_vm._v(" "),_c('div',{ref:"pager",staticClass:"d-flex align-items-start justify-content-between",attrs:{"id":("dt_" + _vm.dtKey + "_pager")}},[(_vm.currentPage)?_c('b-pagination',{staticClass:"pb-2",attrs:{"total-rows":_vm.pager && _vm.pager.total,"per-page":_vm.pager && _vm.pager.per_page,"aria-controls":("dt_" + _vm.dtKey),"disabled":_vm.busy},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-inline-flex align-items-center text-nowrap"},[_c('span',{staticClass:"pr-2 d-none d-md-block d-lg-block"},[_vm._v(_vm._s(_vm.$t('eDt_pageSize')))]),_vm._v(" "),_c('b-form-select',{attrs:{"options":_vm.pageSizes,"size":"sm"},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1),_vm._v(" "),(_vm.pager)?_c('strong',{staticClass:"small d-none d-md-block d-lg-block"},[_vm._v("\n      "+_vm._s(_vm.$t('eDt_iteratorCounts', {
        from: ((_vm.pager.current_page - 1) * _vm.pager.per_page) + 1,
        to: (_vm.pager.current_page) * _vm.pager.per_page,
        total: _vm.pager.total
      }))+"\n    ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }