<template>
  <b-card class="p-0 report-table">
    <b-card class="p-0 report-table">
      <b-card-header class="d-flex align-items-center py-3">
        <div class="ml-auto" />
        <b-dropdown :text="$t('eLt_export_title')" class="m-md-2" size="sm">
          <b-dropdown-item @click="onSaveCsv">
            {{ $t('eLt_export_csv') }}
          </b-dropdown-item>
          <b-dropdown-item @click="onSaveXls">
            {{ $t('eLt_export_xls') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-card-header>
      <b-table
        :fields="fields"
        :items="tableItems"
        :sticky-header="`${height}px`"
        class="text-nowrap"
        striped
        hover
        small
        responsive
        foot-clone
        thead-class="bg-primary text-white text-center small"
      >
        <template #foot(DATA_CREAZIONE)>
          <span />
        </template>
        <template #foot(CREDITI_SPESI)>
          <span v-shtml="totals.CREDITI_SPESI" />
        </template>
        <template #foot(CREDITI_BONUS_SPESI)>
          <span v-shtml="totals.CREDITI_BONUS_SPESI" />
        </template>
        <template #foot(TOT_HOSTESS)>
          <span v-shtml="totals.TOT_HOSTESS" />
        </template>
        <template #foot(NO_WING)>
          <span v-shtml="totals.NO_WING" />
        </template>
        <template #foot(PUBBLICATA)>
          <span v-shtml="totals.PUBBLICATA" />
        </template>
        <template #foot(PERC_PUBBLICATE)>
          <span v-shtml="`${totals.PERC_PUBBLICATE}%`" />
        </template>
        <template #foot(COSTO_HOSTESS_ATTIVA)>
          <span />
        </template>
        <template #foot(ATTIVA)>
          <span v-shtml="totals.ATTIVA" />
        </template>
        <template #foot(INATTIVA)>
          <span v-shtml="totals.INATTIVA" />
        </template>
        <template #foot(CANCELLATA)>
          <span v-shtml="totals.CANCELLATA" />
        </template>
        <template #foot(SOSPESA)>
          <span v-shtml="totals.SOSPESA" />
        </template>
        <template #foot(NASCOSTA)>
          <span v-shtml="totals.NASCOSTA" />
        </template>
      </b-table>
    </b-card>
  </b-card>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'HostesReportTable',
  components: {},
  props: {
    height: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      thBgColor: '#007bff !important',
      thColor: '#fff !important'
    }
  },
  computed: {
    ...mapFields({
      reportData: 'reports.hostesReport.reportData',
      reportTotals: 'reports.hostesReport.reportTotals'
    }),
    items () {
      return this.reportData || []
    },
    totals () {
      return this.reportTotals || {}
    },
    fields () {
      return [
        {
          key: 'DATA_CREAZIONE',
          label: this.$t('rHostess_tbl_f_DATA_CREAZIONE'),
          sortable: false,
          class: 'text-left'
        },
        {
          key: 'CREDITI_SPESI',
          label: this.$t('rHostess_tbl_f_CREDITI_SPESI'),
          sortable: false,
          thClass: 'text-center',
          class: 'text-right'
        },
        {
          key: 'CREDITI_BONUS_SPESI',
          label: this.$t('rHostess_tbl_f_CREDITI_BONUS_SPESI'),
          sortable: false,
          thClass: 'col-md-1 text-center',
          class: 'text-right'
        },
        {
          key: 'TOT_HOSTESS',
          label: this.$t('rHostess_tbl_f_TOT_HOSTESS'),
          sortable: false,
          thClass: 'text-center',
          class: 'text-right'
        },
        {
          key: 'NO_WING',
          label: this.$t('rHostess_tbl_f_NO_WING'),
          sortable: false,
          thClass: 'text-center',
          class: 'text-right'
        },
        {
          key: 'PUBBLICATA',
          label: this.$t('rHostess_tbl_f_PUBBLICATA'),
          sortable: false,
          thClass: 'text-center',
          class: 'text-right'
        },
        {
          key: 'PERC_PUBBLICATE',
          label: this.$t('rHostess_tbl_f_PERC_PUBBLICATE'),
          sortable: false,
          thClass: 'text-center',
          class: 'text-right'
        },
        {
          key: 'COSTO_HOSTESS_ATTIVA',
          label: this.$t('rHostess_tbl_f_COSTO_HOSTESS_ATTIVA'),
          sortable: false,
          thClass: 'col-md-4 text-center'
        },
        {
          key: 'ATTIVA',
          label: this.$t('rHostess_tbl_f_ATTIVA'),
          sortable: false,
          thClass: 'text-center',
          class: 'text-right'
        },
        {
          key: 'INATTIVA',
          label: this.$t('rHostess_tbl_f_INATTIVA'),
          sortable: false,
          thClass: 'text-center',
          class: 'text-right'
        },
        {
          key: 'CANCELLATA',
          label: this.$t('rHostess_tbl_f_CANCELLATA'),
          sortable: false,
          thClass: 'text-center',
          class: 'text-right'
        },
        {
          key: 'SOSPESA',
          label: this.$t('rHostess_tbl_f_SOSPESA'),
          sortable: false,
          thClass: 'text-center',
          class: 'text-right'
        },
        {
          key: 'NASCOSTA',
          label: this.$t('rHostess_tbl_f_NASCOSTA'),
          sortable: false,
          thClass: 'text-center',
          class: 'text-right'
        }
      ]
    },
    tableItems () {
      const items = []
      if (this.items && this.items.length) {
        for (const i of this.items) {
          items.push(i)
        }
      }
      return items
    }
  },
  methods: {
    onSaveCsv () {
      this.$emit('save-csv')
    },
    onSaveXls () {
      this.$emit('save-xls')
    }
  }
}
</script>

<style>
.report-table .table.b-table > thead > tr > .table-b-table-default {
  background:#007bff !important;
  color:#fff !important;
}
.report-table .table.b-table > tfoot > tr > th {
  background: #737373 !important;
  color:#fff !important;
  text-align: right !important;
}
</style>
