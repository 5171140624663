<template>
  <b-card
    ref="table"
    class="p-0 report-table"
  >
    <b-card-header class="d-flex align-items-center py-3">
      <div class="d-inline-flex align-items-center">
        <div class="h5">
          {{ $t('rPayment_total') }}: <span class="text-secondary">
            {{ reportTotals }}
            <font-awesome-icon
              :icon="['fas', 'euro-sign']"
              class="mr-2"
            />
          </span>
        </div>
      </div>
      <div class="ml-auto" />
      <b-dropdown :text="$t('eLt_export_title')" class="m-md-2" size="sm">
        <b-dropdown-item @click="onSaveCsv">
          {{ $t('eLt_export_csv') }}
        </b-dropdown-item>
        <b-dropdown-item @click="onSaveXls">
          {{ $t('eLt_export_xls') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-table
      :fields="fields"
      :items="tableItems"
      :sticky-header="`${height}px`"
      class="text-nowrap"
      striped
      hover
      small
      responsive
      foot-clone
      thead-class="bg-primary text-white text-center small"
    >
      <template
        #[`cell(user)`]="{ item: { user } }"
      >
        <user-profile-item :user="user" />
      </template>
      <template #cell(status)="{ item: { status } }">
        <b-badge :variant="getStatusVariant(status)">
          {{ getStatusText(status) }}
        </b-badge>
      </template>
      <template #cell(market)="{ item: { user } }">
        <market-flag :market="user.market" />
      </template>
      <template #cell(club_member)="{ item }">
        <b-button
          v-if="item.club_member"
          pill
          disabled
          variant="warning"
          size="sm"
        >
          <font-awesome-icon
            :icon="['fas', 'star']"
            class="text-white"
          />
        </b-button>
      </template>
    </b-table>
    <div
      :id="`dt_${dtKey}_pager`"
      ref="pager"
      class="d-flex align-items-start justify-content-between"
    >
      <b-pagination
        v-if="currentPage"
        v-model="currentPage"
        class="pb-2"
        :total-rows="pager && pager.total"
        :per-page="pager && pager.per_page"
        :aria-controls="`dt_${dtKey}`"
        :disabled="busy"
      />
      <div
        class="d-inline-flex align-items-center text-nowrap"
      >
        <span class="pr-2 d-none d-md-block d-lg-block">{{ $t('eDt_pageSize') }}</span>
        <b-form-select
          v-model="pageSize"
          :options="pageSizes"
          size="sm"
        />
      </div>
      <strong v-if="pager" class="small d-none d-md-block d-lg-block">
        {{ $t('eDt_iteratorCounts', {
          from: ((pager.current_page - 1) * pager.per_page) + 1,
          to: (pager.current_page) * pager.per_page,
          total: pager.total
        }) }}
      </strong>
    </div>
  </b-card>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { isServer } from '@/mixins/helpers'

export default {
  name: 'PaymentsReportTable',
  components: {},
  props: {
    height: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      dtKey: 'payments_report',
      busy: false,
      thBgColor: '#007bff !important',
      thColor: '#fff !important'
    }
  },
  computed: {
    ...mapFields({
      currentPage: 'reports.paymentsReport.page',
      pageSize: 'reports.paymentsReport.pageSize',
      reportData: 'reports.paymentsReport.reportData',
      reportTotals: 'reports.paymentsReport.reportTotals',
      reportPager: 'reports.paymentsReport.reportPager'
    }),
    items () {
      return this.reportData || []
    },
    totals () {
      return this.reportTotals || {}
    },
    fields () {
      const fields = [
        {
          key: 'id',
          label: this.$t('rPayment_f_id'),
          sortable: false
        },
        {
          key: 'created_at',
          label: this.$t('rPayment_f_createdAt'),
          sortable: false,
          formatter: (value) => {
            return this.$dateFormat(value)
          },
          class: 'col-md-2 text-center'
        },
        {
          key: 'user',
          label: this.$t('rPayment_f_user'),
          sortable: false,
          thClass: 'col-md-3 text-center'
        },
        {
          key: 'user_type',
          label: this.$t('rPayment_f_userType'),
          sortable: false,
          formatter: (value, key, item) => {
            return item.user_type === 'client'
              ? this.$t('rPayment_client')
              : this.$t('rPayment_hostess')
          },
          thClass: 'col-md-1 text-center',
          class: 'text-center'
        },
        {
          key: 'registration_date',
          label: this.$t('rPayment_f_registrationDate'),
          sortable: false,
          formatter: (value, key, item) => {
            return this.$dateFormat(item.registration_date)
          },
          thClass: 'col-md-2 text-center'
        },
        {
          key: 'package',
          label: this.$t('rPayment_f_package'),
          sortable: false,
          thClass: 'col-md-2 text-center'
        },
        {
          key: 'credits',
          label: this.$t('rPayment_f_credits'),
          sortable: false,
          thClass: 'col-md-1 text-center',
          class: 'text-center'
        },
        {
          key: 'amount_in_euro',
          label: this.$t('rPayment_f_spent'),
          sortable: false,
          thClass: 'col-md-1 text-center',
          class: 'text-center'
        },
        {
          key: 'gateway_id',
          label: this.$t('rPayment_f_gateway'),
          sortable: false,
          thClass: 'col-md-1 text-center'
        },
        {
          key: 'platform',
          label: this.$t('rPayment_f_platform'),
          sortable: false,
          thClass: 'col-md-1 text-center'
        },
        {
          key: 'club_member',
          label: this.$t('rPayment_f_club'),
          sortable: false,
          thClass: 'col-md-1 text-center',
          class: 'text-center'
        },
        {
          key: 'status',
          label: this.$t('rPayment_f_result'),
          sortable: false,
          thClass: 'col-md-1 text-center',
          class: 'text-center'
        }
      ]
      return fields
    },
    tableItems () {
      const items = []
      if (this.items && this.items.length) {
        for (const i of this.items) {
          items.push(i)
        }
      }
      return items
    },
    pager () {
      if (this.reportPager) {
        return {
          count: this.reportPager.count,
          current_page: this.reportPager.current_page,
          links: this.reportPager.links,
          per_page: this.reportPager.per_page,
          total: this.reportPager.total,
          total_pages: this.reportPager.total_pages
        }
      }
      return {
        count: 0,
        current_page: 0,
        links: [],
        per_page: 0,
        total: 0,
        total_pages: 0
      }
    },
    pageSizes () {
      const values = [
        {
          value: 10,
          text: 10
        },
        {
          value: 25,
          text: 25
        },
        {
          value: 50,
          text: 50
        },
        {
          value: 100,
          text: 100
        },
        {
          value: 200,
          text: 200
        },
        {
          value: 500,
          text: 500
        }
      ]
      const idx = values.findIndex((v) => {
        return v.value === this.pageSize
      })
      if (idx < 0) {
        values.push({
          value: this.pageSize,
          text: this.pageSize
        })
        values.sort((a, b) => {
          if (a.value < b.value) {
            return -1
          }
          if (a.value > b.value) {
            return 1
          }
          return 0
        })
      }
      return values
    }
  },
  watch: {
    currentPage (n, o) {
      if (n !== o && !this.skipReload) {
        this.onPageChanged(n)
      }
    },
    pageSize (n, o) {
      if (n !== o) {
        this.currentPage = 1
        this.onPageSizeChanged(n)
      }
    }
  },
  methods: {
    onPageChanged (page) {
      this.$emit('page-changed', page)
      if (!isServer && window) {
        const havBar = document.querySelector('.wrapper .content .navbar')?.clientHeight || 0
        const table = this.$refs.table.offsetTop || 0
        document.querySelector('.report').scrollTo({ top: table - havBar, behavior: 'smooth' })
        this.$nextTick(() => {
          const $t = document.querySelector('.report-table .table-responsive')
          $t.scrollTo({ top: 0 })
        })
      }
    },
    onPageSizeChanged (size) {
      this.$emit('page-size-changed', size)
      if (!isServer && window) {
        const havBar = document.querySelector('.wrapper .content .navbar')?.clientHeight || 0
        const table = this.$refs.table.offsetTop || 0
        document.querySelector('.report').scrollTo({ top: table - havBar, behavior: 'smooth' })
        this.$nextTick(() => {
          const $t = document.querySelector('.report-table .table-responsive')
          $t.scrollTo({ top: 0 })
        })
      }
    },
    onSaveCsv () {
      this.$emit('save-csv')
    },
    onSaveXls () {
      this.$emit('save-xls')
    },
    getStatusVariant (status) {
      let variant = 'warning'
      if (status === 'success') {
        variant = 'success'
      } else if (status === 'failed') {
        variant = 'danger'
      }
      return variant
    },
    getStatusText (status) {
      let txt = this.$t('rPayment_status_pending')
      if (status === 'success') {
        txt = this.$t('rPayment_status_completed')
      } else if (status === 'failed') {
        txt = this.$t('rPayment_status_failed')
      }
      return txt
    }
  }
}
</script>

<style>
.report-table .table.b-table > thead > tr > .table-b-table-default {
  background:#007bff !important;
  color:#fff !important;
}
.report-table .table.b-table > tfoot > tr > th {
  background: #737373 !important;
  color:#fff !important;
  text-align: right !important;
}
</style>
